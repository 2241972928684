import {
  ExperienceValueLabel,
  JobsSortByKey,
  LastUpdated,
} from 'src/common/enums/jobs';
import { JobType, WorkArrangementOption } from 'src/global/models/Job';
import {
  FilterRouterQueryKey,
  SalaryFilterInput,
  SearchRouterQueryKey,
  WorkArrangementOptionsFilterInput,
  YearsOfExperienceFilterInput,
} from 'src/modules/Opportunities/pages/Explore/types';
import { HierarchicalLocation } from 'src/modules/Profile/graphql/hierarchicalLocations';
import { HierarchyJobCategoryBase } from 'src/modules/Profile/graphql/jobCategoriesAndTitles';

export type JobSearchFiltersResponse = {
  lastUpdatedAtRanges: LastUpdated[];
  workArrangementOptions: WorkArrangementOption[];
  jobTypes: JobType[];
  cities: { name: string; id: number; country: { name: string } }[];
  hierarchicalLocations?: HierarchicalLocation[];
  citySubDivisions?: { name: string; id: number; city: { name: string } }[];
  yearsOfExperienceRanges: ExperienceValueLabel[];
  currencies: string[];
  companies: { label: string; value: string }[];
  jobCategories: { name: string; id: number }[];
  jobRoles?: HierarchyJobCategoryBase[];
  educationLevels?: string[];
};

export const JobFilterKey = {
  SortBy: FilterRouterQueryKey.SortBy,
  LastUpdated: FilterRouterQueryKey.LastUpdated,
  WorkArrangementOptions: FilterRouterQueryKey.WorkArrangementOptions,
  JobType: FilterRouterQueryKey.JobType,
  Cities: FilterRouterQueryKey.Cities,
  CitySubDivisions: FilterRouterQueryKey.CitySubDivisions,
  FilterLocationIds: FilterRouterQueryKey.FilterLocationIds,
  JobRoles: FilterRouterQueryKey.JobRoles,
  Companies: FilterRouterQueryKey.Companies,
  Experience: 'yearsOfExperienceFilter',
  Salary: 'SalaryFilter',
  Country: SearchRouterQueryKey.Country,
  HierarchicalJobCategoryIds: FilterRouterQueryKey.HierarchicalJobCategoryIds,
  EducationLevel: FilterRouterQueryKey.EducationLevel,
} as const;

export type JobFilterKeyValue =
  (typeof JobFilterKey)[keyof typeof JobFilterKey];

export type JobFilterState = {
  [JobFilterKey.SortBy]?: JobsSortByKey;
  [JobFilterKey.LastUpdated]?: LastUpdated;
  [JobFilterKey.WorkArrangementOptions]?: WorkArrangementOptionsFilterInput;
  [JobFilterKey.JobType]?: JobType[];
  [JobFilterKey.Cities]?: string[];
  [JobFilterKey.CitySubDivisions]?: string[];
  [JobFilterKey.FilterLocationIds]?: string[];
  [JobFilterKey.Companies]?: string[];
  [JobFilterKey.JobRoles]?: string[];
  [JobFilterKey.Experience]?: YearsOfExperienceFilterInput;
  [JobFilterKey.Salary]?: SalaryFilterInput;
  [JobFilterKey.Country]?: string;
  [JobFilterKey.HierarchicalJobCategoryIds]?: string[];
  [JobFilterKey.EducationLevel]?: string[];
};

export type FilterOption = {
  label: React.ReactNode;
  asString?: string;
  value: string;
};

export type FilterProps<Value> = {
  name: string;
  value: Value;
  options: FilterOption[];
  setFilterState: (name: string, value: Value) => void;
  shouldUseFilter?: boolean;
  filterEmptyMessage?: React.ReactNode;
};
