// Campaigns
export const CAMPAIGN_TEMPLATES = {
  default: {
    attr: {
      title: 'title',
      desc: 'desc',
      ctaURL: 'ctaURL',
      ctaText: 'ctaText',
    },
  },
};

// Other shared constants

export const JOBS_PER_ROW = {
  mobile: 1,
  tablet: 2,
  desktop: 2,
};

export enum UTM_REFERRER {
  COMPANY_PROFILE = 'company_profile',
  EXPLORE = 'explore',
  BOOKMARKED = 'bookmarked',
  SIMILAR = 'similar',
  BLOG_IFRAME_JOB_LIST = 'blog_iframe_job_list',
  FYP = 'fyp',
}

export const DEFAULT_JOB_EXPLORE_URL = '/opportunities/jobs/explore';

export enum OpportunitiesShowLoginPopupVariant {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
}

export enum ShowLoginPopupVariant {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
}

export const UTM_REFERRER_SOURCE = {
  [UTM_REFERRER.FYP]: 'For You',
  [UTM_REFERRER.COMPANY_PROFILE]: 'Company Profile',
  [UTM_REFERRER.EXPLORE]: 'Explore',
  [UTM_REFERRER.SIMILAR]: 'Similar Job',
};
