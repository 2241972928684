import { ReduxThunkAction } from 'src/global/store';

const Actions = {
  CLICK_SIGN_UP_BUTTON_ON_COMPANIES_WITHOUT_JOBS:
    'glints/GoogleTagManager/CLICK_SIGN_UP_BUTTON_ON_COMPANIES_WITHOUT_JOBS',
  VIEW_COMPANIES_PAGES: 'glints/GoogleTagManager/VIEW_COMPANIES_PAGES',
};

type CompaniesProperties = {
  companyID: string;
  companyCountryCode: string;
  companySize: string;
  companyStatus: string;
};

type ViewCompaniesProperties = {
  landingPageURL: string;
  companyCountryCode: string;
  companySize: string;
  companyStatus: string;
  companyIndustry: string;
  hasDescription: boolean;
  hasOpenJobs: boolean;
};

export const trackSignUpButtonOnCompaniesWithoutJobs =
  (eventProperties: CompaniesProperties): ReduxThunkAction<any> =>
  (dispatch) =>
    dispatch({
      type: Actions.CLICK_SIGN_UP_BUTTON_ON_COMPANIES_WITHOUT_JOBS,
      payload: eventProperties,
    });

export const trackViewCompaniesPages =
  (eventProperties: ViewCompaniesProperties): ReduxThunkAction<any> =>
  (dispatch) =>
    dispatch({
      type: Actions.VIEW_COMPANIES_PAGES,
      payload: eventProperties,
    });
