import { handleActions } from 'redux-actions';

import { DstAction } from 'src/middleware/api/interfaces';

import { Actions } from '../actions/company';

export interface CompanyState {
  loading: boolean;
  error: any;
  value: string;
  jobs: string[];
}

export const initialState: CompanyState = {
  loading: false,
  error: null,
  value: null,
  jobs: [],
};

export const CompanyReducer = handleActions<CompanyState, any>(
  {
    [Actions.COMPANY_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: null,
    }),
    [Actions.COMPANY_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
    }),
    [Actions.COMPANY_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: null,
    }),
    [Actions.COMPANY_JOBS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      jobs: [],
    }),
    [Actions.COMPANY_JOBS_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      jobs: action.response.result,
    }),
    [Actions.COMPANY_JOBS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      jobs: [],
    }),
    [Actions.RESET_COMPANY]: () => initialState,
  },
  initialState
);
