
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/companies/id",
      function () {
        return require("private-next-pages/companies/id.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/companies/id"])
      });
    }
  