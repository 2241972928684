import { RawDraftContentState } from 'draft-js';
import { camelCase, inRange } from 'lodash-es';
import { IntlShape } from 'react-intl';

const formatCurrencyCode = (CurrencyCode: string) => {
  switch (CurrencyCode) {
    case 'IDR':
      return 'Rp';
    case 'VND':
      return '₫';
    case 'TWD':
      return 'NT$';
    default:
      return CurrencyCode;
  }
};

// For the case where the number units are the same, e.g. ₫ 5 Tr-7 Tr -> ₫ 5-7 Tr
const removeCommonNumberUnit = (numberRange: string) => {
  const numberUnits = numberRange.match(/[a-zA-Z]+/g);
  if (numberUnits && numberUnits[0] === numberUnits[1]) {
    return numberRange.replace(/[a-zA-Z]+/, '').replace(/\s-/g, '-');
  }
  return numberRange;
};

const formatCompactNumber = (intl: IntlShape, number: number) =>
  intl.formatNumber(number, {
    notation: 'compact',
  });

export const formatCurrency = (
  intl: IntlShape,
  CurrencyCode: string,
  minAmount: number,
  maxAmount?: number
) => {
  const currencyCode = formatCurrencyCode(CurrencyCode);
  const formattedMinAmount = formatCompactNumber(intl, minAmount);
  const formattedMaxAmount = maxAmount && formatCompactNumber(intl, maxAmount);
  return maxAmount
    ? removeCommonNumberUnit(
        `${currencyCode} ${formattedMinAmount}-${formattedMaxAmount}`
      )
    : `${currencyCode} ${formattedMinAmount}`;
};

export const joinStringsWithSeparator = (
  listOfStrings: Array<string | undefined>,
  separator = ' '
) =>
  listOfStrings
    .map((string) => string?.trim())
    .filter(Boolean)
    .join(separator);

export const isLastContentListItemType = (
  draftContent?: RawDraftContentState
): boolean => {
  if (!draftContent) return false;
  const lastBlock = draftContent.blocks.at(-1);
  return (
    lastBlock?.type === 'unordered-list-item' ||
    lastBlock?.type === 'ordered-list-item'
  );
};

export const getShortcutCreatorName = (name?: string): string => {
  if (!name) return '';
  const words = name.trim().split(/\s+/);
  const firstInitialLetter = words[0].charAt(0).toUpperCase();

  if (words.length < 2) {
    return firstInitialLetter;
  }
  const secondInitialLetter = words[1].charAt(0).toUpperCase();

  return firstInitialLetter + secondInitialLetter;
};

const ResponseTimeLevel = {
  FEW_MINUTES: 'FEW_MINUTES',
  AN_HOUR: 'AN_HOUR',
  FEW_HOURS: 'FEW_HOURS',
  A_DAY: 'A_DAY',
  FEW_DAYS: 'FEW_DAYS',
  A_WEEK: 'A_WEEK',
} as const;
type ResponseTimeLevel =
  (typeof ResponseTimeLevel)[keyof typeof ResponseTimeLevel];

const oneDay = 24;
const threeDays = oneDay * 3;
const sevenDays = oneDay * 7;
const halfDay = oneDay / 2;
const oneHour = 1;
const fifteenMinutes = 0.25;

export const getResponseTimeLevel = (
  responseTime: number
): ResponseTimeLevel => {
  if (inRange(responseTime, threeDays, sevenDays)) {
    return ResponseTimeLevel.A_WEEK;
  }
  if (inRange(responseTime, oneDay, threeDays)) {
    return ResponseTimeLevel.FEW_DAYS;
  }
  if (inRange(responseTime, halfDay, oneDay)) {
    return ResponseTimeLevel.A_DAY;
  }
  if (inRange(responseTime, oneHour, halfDay)) {
    return ResponseTimeLevel.FEW_HOURS;
  }
  if (inRange(responseTime, fifteenMinutes, oneHour)) {
    return ResponseTimeLevel.AN_HOUR;
  }
  return ResponseTimeLevel.FEW_MINUTES;
};

export const getResponseTime = (responseTime?: number) => {
  return responseTime ? camelCase(getResponseTimeLevel(responseTime)) : null;
};
