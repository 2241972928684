import React from 'react';
import get from 'lodash/get';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { fetchCompany, resetCompany } from 'src/actions/company';
import { getCompanyLinkProps } from 'src/common/links';
import {
  getCurrentCompany,
  getCurrentCompanyError,
} from 'src/selectors/company';

import CompanyPage from './CompanyPage';

class CompanyPageContainer extends React.PureComponent {
  static propTypes = {
    resetCompany: PropTypes.func.isRequired,

    company: PropTypes.object,
    companyError: PropTypes.object,
    router: PropTypes.object,
  };

  componentDidMount() {
    const { router } = this.props;
    const { id, companyName: name, ...restQueries } = router.query;
    // If this page is redirected from the login page, the url in the browser address bar may not be in the pattern we want. (eg: /companies/[id]?id=xxx)
    // In such case, if the user attempt to refresh the page they'll be redirected to /404 because the server does not recognize the url.
    // TODO: look into how to fix or workaround this in the future.
    const linkProps = getCompanyLinkProps({ name, id });
    if (linkProps) {
      router.replace(
        {
          pathname: linkProps.href.pathname,
          query: {
            ...linkProps.href.query,
            // this is important, otherwise bookmarking this company after redirect from login wouldn't work.
            ...restQueries,
          },
        },
        { pathname: linkProps.as, query: restQueries },
        { shallow: true }
      );
    }
  }

  componentDidUpdate() {
    const { router, companyError } = this.props;
    if (companyError) {
      const statusCode = get(companyError, 'response.status');
      if (statusCode === 403) {
        router.push('/403');
      } else {
        router.push('/404');
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCompany();
  }

  render() {
    const { company } = this.props;

    return <CompanyPage company={company} />;
  }
}

const mapStateToProps = state => ({
  company: getCurrentCompany(state),
  companyError: getCurrentCompanyError(state),
});

const mapDispatchToProps = {
  resetCompany,
};

CompanyPageContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyPageContainer);

CompanyPageContainer.getInitialProps = async ({
  reduxStore: { dispatch },
  query,
}) => {
  const { id } = query;
  const fetchCurrentCompany = () => dispatch(fetchCompany(id));

  await fetchCurrentCompany();

  return {};
};

export default CompanyPageContainer;
