import React from 'react';
import { defineMessages, FormattedMessage, Message } from 'react-intl';

import { LastUpdated } from 'src/common/enums/jobs';
import { JobType, WorkArrangementOption } from 'src/global/models/Job';

import { JobFilterKey, JobFilterKeyValue } from './types';

export const CollapsibleLabelAndFilterKeyMap: {
  [key in JobFilterKeyValue]?: Message;
} = defineMessages({
  [JobFilterKey.SortBy]: {
    id: 'text-prioritise-by',
    defaultMessage: 'Prioritise By',
  },
  [JobFilterKey.LastUpdated]: {
    id: 'job-filter.last-updated.title',
    defaultMessage: 'Last Updated',
  },

  [JobFilterKey.JobType]: {
    id: 'job-filter.job-type.title',
    defaultMessage: 'Job Type',
  },

  [JobFilterKey.Cities]: {
    id: 'job-filter.cities.title',
    defaultMessage: 'Cities',
  },

  [JobFilterKey.FilterLocationIds]: {
    id: 'text-hierarchical-location-level',
    defaultMessage:
      '{level, select, 1 {Country} 2 {Province} 3 {City} 4 {District} other {District}}',
  },

  [JobFilterKey.CitySubDivisions]: {
    id: 'job-filter.citySubDivisions.title',
    defaultMessage: 'Specific Areas of {city}',
  },

  [JobFilterKey.Salary]: {
    id: 'job-filter.salary.title',
    defaultMessage: 'Salary',
  },
  [JobFilterKey.Experience]: {
    id: 'job-filter.experience.title',
    defaultMessage: 'Experience',
  },
  [JobFilterKey.JobRoles]: {
    id: 'interactive-job-role',
    defaultMessage: 'Job Role',
  },
  [JobFilterKey.EducationLevel]: {
    id: 'text-education-level',
    defaultMessage: 'Education Level',
  },

  [JobFilterKey.Companies]: {
    id: 'job-filter.companies.title',
    defaultMessage: 'Companies',
  },

  [JobFilterKey.WorkArrangementOptions]: {
    id: 'job-filter.work-arrangement-options.title',
    defaultMessage: 'Work Arrangement Options',
  },
});

export const LastUpdatedMessageMap = {
  [LastUpdated.Past24Hours]: (
    <FormattedMessage
      id="job-filter.last-updated.past-24-hours"
      defaultMessage="Past 24 Hours"
    />
  ),
  [LastUpdated.PastWeek]: (
    <FormattedMessage
      id="job-filter.last-updated.past-week"
      defaultMessage="Past Week"
    />
  ),
  [LastUpdated.PastMonth]: (
    <FormattedMessage
      id="job-filter.last-updated.past-month"
      defaultMessage="Past Month"
    />
  ),
  [LastUpdated.AnyTime]: (
    <FormattedMessage
      id="job-filter.last-updated.any-time"
      defaultMessage="Any Time"
    />
  ),
};

export const WorkArrangementOptionsMessageMap = {
  [WorkArrangementOption.ONSITE]: (
    <FormattedMessage id="job-filter.onsite" defaultMessage="Onsite" />
  ),
  [WorkArrangementOption.HYBRID]: (
    <FormattedMessage id="job-filter.hybrid" defaultMessage="Hybrid" />
  ),
  [WorkArrangementOption.REMOTE]: (
    <FormattedMessage id="job-filter.remote" defaultMessage="Remote" />
  ),
};

export const JobTypeMessageMap = {
  [JobType.INTERNSHIP]: (
    <FormattedMessage
      id="job-filter.job-type.internship"
      defaultMessage="Internship"
    />
  ),
  [JobType.FULL_TIME]: (
    <FormattedMessage
      id="job-filter.job-type.full-time"
      defaultMessage="Full-time"
    />
  ),
  [JobType.PART_TIME]: (
    <FormattedMessage
      id="job-filter.job-type.part-time"
      defaultMessage="Part-time"
    />
  ),
  [JobType.PROJECT_BASED]: (
    <FormattedMessage
      id="job-filter.job-type.project-based"
      defaultMessage="Freelance"
    />
  ),
  [JobType.CONTRACT]: (
    <FormattedMessage id="text-contract" defaultMessage="Contract" />
  ),
  [JobType.DAILY]: <FormattedMessage id="text-daily" defaultMessage="Daily" />,
};

export { ExperienceMessageMap } from 'src/common/messages/jobs';
