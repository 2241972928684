import { createAction } from 'redux-actions';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export const Actions = {
  COMPANY_REQUEST: 'glints/company/COMPANY_REQUEST',
  COMPANY_SUCCESS: 'glints/company/COMPANY_SUCCESS',
  COMPANY_FAILURE: 'glints/company/COMPANY_FAILURE',

  COMPANY_JOBS_REQUEST: 'glints/company/COMPANY_JOBS_REQUEST',
  COMPANY_JOBS_SUCCESS: 'glints/company/COMPANY_JOBS_SUCCESS',
  COMPANY_JOBS_FAILURE: 'glints/company/COMPANY_JOBS_FAILURE',

  RESET_COMPANY: 'glints/company/RESET_COMPANY',
};

export const resetCompany = createAction(Actions.RESET_COMPANY);

export function fetchCompany(id: string, options?: CallAPIOptions) {
  return {
    [CALL_API]: {
      types: [
        Actions.COMPANY_REQUEST,
        Actions.COMPANY_SUCCESS,
        Actions.COMPANY_FAILURE,
      ],
      endpoint: `companies/${id}`,
      schema: Schemas.COMPANY,
      options: {
        ...options,
        params: {
          ...(options ? options.params : {}),
          includeHierarchicalLocation: true,
          include: JSON.stringify([
            { association: 'Industry', attributes: ['name'] },
            { association: 'Country', attributes: ['name'] },
            { association: 'City', attributes: ['name'] },
          ]),
        },
      },
    },
  };
}

const defaultFetchCompanyJobsParams = {
  include: 'Company, JobSalaries, City, Country',
  where: {
    status: 'OPEN',
  },
};

export function fetchCompanyJobs(id: string, options?: CallAPIOptions) {
  return {
    [CALL_API]: {
      types: [
        Actions.COMPANY_JOBS_REQUEST,
        Actions.COMPANY_JOBS_SUCCESS,
        Actions.COMPANY_JOBS_FAILURE,
      ],
      endpoint: `companies/${id}/jobs`,
      schema: Schemas.OPPORTUNITY_ARRAY,
      options: {
        ...options,
        params: {
          ...defaultFetchCompanyJobsParams,
          ...options?.params,
        },
      },
    },
  };
}

export const companyPageFetchCompanyJobsParams = {
  isPublic: true,
  include: JSON.stringify([{ association: 'City' }]),
  attributes: JSON.stringify([
    'createdAt',
    'CityId',
    'title',
    'id',
    'intro',
    'status',
  ]),
};
