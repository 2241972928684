import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

export const TabListItems = [
  'topJobsTab',
  'descriptionTab',
  'cultureTab',
  'contactTab',
  'galleryTab',
  'jobsTab',
  'similarCompaniesTab',
] as const;

export type CompanyTabTypes = (typeof TabListItems)[number];

const tabMessages = {
  topJobsTab: defineMessage({
    id: 'text-top-jobs',
    defaultMessage: 'Top Jobs',
  }),
  descriptionTab: defineMessage({
    id: 'company-page.overview-section.description.title',
    defaultMessage: 'Description',
  }),
  cultureTab: defineMessage({
    id: 'company-page.overview-section.culture.title',
    defaultMessage: 'Culture',
  }),
  contactTab: defineMessage({
    id: 'company-page.overview-section.contact.title',
    defaultMessage: 'Contact',
  }),
  galleryTab: defineMessage({
    id: 'company-page.gallery-section.title',
    defaultMessage: 'Gallery',
  }),
  jobsTab: defineMessage({
    id: 'company-page.jobs-section.title-v2',
    defaultMessage: 'Jobs',
  }),
  similarCompaniesTab: defineMessage({
    id: 'text-similar-companies',
    defaultMessage: 'Similar Companies',
  }),
};

export const getLocalizationTab = (
  tab: CompanyTabTypes,
  numberOfJobs?: number
): React.JSX.Element => {
  const message = tabMessages[tab];

  return (
    <FormattedMessage
      {...message}
      values={tab === 'jobsTab' ? { numberOfJobs } : undefined}
    />
  );
};
