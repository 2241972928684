import { createSelector } from 'reselect';

import { State } from 'src/global/store';

import { getCompanies, getOpportunities } from './api';

const getCurrentCompanyId = (state: State) => state.company.value;
export const getCurrentCompanyError = (state: State) => state.company.error;

export const getCurrentCompany = createSelector(
  [getCurrentCompanyId, getCompanies],
  (id, companies) => companies[id]
);

const getCurrentCompanyJobIds = (state: State) => state.company.jobs || [];

export const getCurrentCompanyJobs = createSelector(
  [getCurrentCompanyJobIds, getOpportunities],
  (ids, opportunities) =>
    ids
      .map((id) => opportunities[id])
      .filter((opportunity) => opportunity.status === 'OPEN')
);
